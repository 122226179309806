import _fiber from "@react-three/fiber";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
var fiber = _fiber;
console.warn("react-three-fiber has been deprecated, please use @react-three/fiber (https://www.npmjs.com/package/@react-three/fiber) from now on");
Object.keys(fiber).forEach(function (k) {
  if (k !== "default" && !exports.hasOwnProperty(k)) Object.defineProperty(exports, k, {
    enumerable: true,
    get: function () {
      return fiber[k];
    }
  });
});
export default exports;